<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <router-link
              :to="{
                name: 'yearly-training-plan-show',
                params: this.$route.params.id,
              }"
            >
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
              >
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
          <h4 class="page-title">Add Yearly Training Plan</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="form-horizontal">
              <div class="row mb-2">
                <label for="inputEmail3" class="col-2 col-xl-2 col-form-label"
                  >Employee Type</label
                >
                <div class="col-4 col-xl-5">
                  <v-select
                    v-model="form.employee_type_id"
                    label="name"
                    :options="employee_types"
                    :reduce="(name) => name.id"
                    @option:selected="changeEmployeeType"
                    :clearable="false"
                  >
                  </v-select>
                  <v-errors
                    :vuelidateErrors="{
                      errors: v$.form.employee_type_id.$errors,
                      value: 'Employee Type',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-2" v-if="form.employee_type_id == '2'">
                <label for="inputEmail3" class="col-2 col-xl-2 col-form-label"
                  >Position Level</label
                >
                <div class="col-4 col-xl-5">
                  <v-select
                    v-model="form.position_level"
                    label="name"
                    :options="position_levels"
                    :reduce="(name) => name.id"
                    @option:selected="changePositionLevel"
                    :clearable="false"
                  >
                  </v-select>
                  <!-- <v-errors
                    :vuelidateErrors="{
                      errors: v$.form.position_level.$errors,
                      value: 'Position Level',
                    }"
                  ></v-errors> -->
                </div>
              </div>

              <div class="row mb-2" v-if="form.employee_type_id == '1'">
                <label for="inputEmail3" class="col-2 col-xl-2 col-form-label"
                  >Program</label
                >
                <div class="col-4 col-xl-5">
                  <v-select
                    v-model="form.program_id"
                    label="name"
                    :options="programs"
                    :reduce="(name) => name.id"
                    @option:selected="changeProgramName"
                    :clearable="false"
                  >
                  </v-select>
                  <!-- <v-errors
                    :vuelidateErrors="{
                      errors: v$.form.program_id.$errors,
                      value: 'Program',
                    }"
                  ></v-errors> -->
                </div>
              </div>

              <div class="row mb-2" v-if="form.employee_type_id == '1'">
                <label for="inputEmail3" class="col-2 col-xl-2 col-form-label"
                  >Levels</label
                >
                <div class="col-4 col-xl-5">
                  <v-select
                    v-model="form.level"
                    label="name"
                    :options="levels"
                    :reduce="(name) => name.name"
                    @option:selected="changeLevel"
                    :clearable="false"
                  >
                  </v-select>
                  <!-- <v-errors
                    :vuelidateErrors="{
                      errors: v$.form.level.$errors,
                      value: 'Levels',
                    }"
                  ></v-errors> -->
                </div>
              </div>

              <div class="row mb-3">
                <label for="inputEmail3" class="col-2 col-xl-2 col-form-label"
                  >Modules</label
                >
                <div class="col-4 col-xl-5">
                  <v-select
                    v-model="form.module_id"
                    label="name"
                    :options="modules"
                    :reduce="(name) => name.id"
                    @option:selected="getPlansByModule"
                    :clearable="false"
                  >
                  </v-select>
                  <v-errors
                    :vuelidateErrors="{
                      errors: v$.form.module_id.$errors,
                      value: 'Module',
                    }"
                  ></v-errors>
                </div>
              </div>

              <place-holder v-if="loading"></place-holder>

              <template v-if="!loading">
                <div
                  class="row"
                  v-for="(month, month_key) in plans.months"
                  :key="month_key"
                >
                  <label
                    for="inputEmail3"
                    class="col-2 col-xl-2 col-form-label"
                    >{{ month }}</label
                  >

                  <div
                    class="col-2 col-xl-2"
                    v-for="(week, week_key) in plans.weeks"
                    :key="week_key"
                  >
                    <div class="form-check col-form-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="month_key + week_key"
                        :value="month_key + ',' + week_key"
                        v-model="form.checkedPlans"
                      />
                      <label
                        class="form-check-label"
                        :for="month_key + week_key"
                        >{{ week }}</label
                      >
                    </div>
                  </div>
                </div>
              </template>

              <div class="justify-content-end row mt-3" v-if="!loading">
                <div class="col-8 col-xl-9">
                  <router-link
                    :to="{
                      name: 'yearly-training-plan-show',
                      params: { id: this.$route.params.id },
                    }"
                  >
                    <button type="button" class="btn w-sm btn-secondary me-5">
                      Cancel
                    </button>
                  </router-link>
                  <button
                    :disabled="isLoading ? true : false"
                    type="button"
                    class="btn w-sm btn-success waves-effect waves-light px-3"
                    @click="savePlan"
                  >
                    <span
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ isLoading == true ? "Loading..." : "Save" }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  data() {
    return {
      plans: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      loading: false,
      isLoading: false,
      form: {
        employee_type_id: null,
        position_level: null,
        program_id: null,
        level: null,
        module_id: null,
        checkedPlans: [],
      },
      employee_types: [],
      position_levels: [],
      programs: [],
      levels: [],
      modules: [],
    };
  },
  validations() {
    return {
      form: {
        employee_type_id: { required },
        // position_level: { required },
        // program_id: { required },
        // level: { required },
        module_id: { required },
      },
    };
  },
  methods: {
    async getYearlyTrainingPlan() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/yearly-training-plans/${this.$route.params.id}`
        )
        .then((response) => {
          this.plans = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async getEmployeeTypes() {
      await axios
        .get(`${this.baseUrl}admin/v1/employee-types`)
        .then((response) => {
          this.employee_types = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Employee Type");
        });
    },
    getPositionLevels() {
      axios
        .get(`${this.baseUrl}admin/v1/position-level`)
        .then((response) => {
          this.position_levels = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Position Levels!");
        });
    },
    getPrograms() {
      axios
        .get(`${this.baseUrl}admin/v1/programs`)
        .then((response) => {
          this.programs = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getAllLevels() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/modules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    changeEmployeeType() {
      this.form.position_level = "";
      this.form.program_id = "";
      this.form.level = "";
      this.form.module_id = "";
    },
    changePositionLevel() {
      this.form.module_id = "";
    },
    changeProgramName() {
      this.form.level = "";
      this.form.module_id = "";
    },
    changeLevel() {
      this.form.module_id = "";
    },
    async getPlansByModule() {
      this.loading = true;
      let data = {
        program_id: this.form.program_id,
        level: this.form.level,
        position_level: this.form.position_level,
      };
      data = JSON.stringify(data);
      await axios
        .get(
          `${this.baseUrl}admin/v1/yearly-training-plans/${this.$route.params.id}/${this.form.module_id}/${this.form.employee_type_id}/plans?data=${data}`
        )
        .then((response) => {
          this.form.checkedPlans = response.data.data;
          this.loading = false;
        });
    },
    async savePlan() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      const plans = this.form.checkedPlans.map((plan) => {
        const planArr = plan.split(",");
        return {
          month: planArr[0],
          week: planArr[1],
        };
      });

      this.isLoading = true;

      await axios
        .post(
          `${this.baseUrl}admin/v1/yearly-training-plans/${this.$route.params.id}/${this.form.module_id}/plans`,
          {
            employee_type_id: this.form.employee_type_id,
            position_level: this.form.position_level,
            program_id: this.form.program_id,
            level: this.form.level,
            plans: plans,
          }
        )
        .then((res) => {
          // this.$router.push({
          //   name: "yearly-training-plan-show",
          //   params: { id: this.$route.params.id },
          // });
          this.toast.success(res.data.message);
          this.isLoading = false;
        })
        .catch(() => {
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getYearlyTrainingPlan();
    this.getEmployeeTypes();
    this.getPositionLevels();
    this.getPrograms();
    this.getAllLevels();
    this.getAllModules();
  },
};
</script>

<style></style>
